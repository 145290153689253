import { MouseEventHandler } from "react";
import "./LeafBannerToast.scss";
import { IconClose } from "../../icons/IconClose/IconClose";

type BannerToastProps = {
  theme: "green" | "red" | "yellow" | "blue" | "dark";
  show: boolean;
  children?: React.ReactNode;
  closeFunction: MouseEventHandler<HTMLDivElement>;
  title?: string;
  message?: string;
};

export const LeafBannerToast = ({ theme, show, children, closeFunction }: BannerToastProps) => {
  return show ? (
    <div data-testid="leafBannerToast" role="alert" className={`leafBannerToast leafBannerToast__${theme}`}>
      <div className="leafBannerToast__message" tabIndex={0}>
        {children}
      </div>
      <div
        data-testid="leafBannerToast__click"
        role={"button"}
        className="leafBannerToast__closeButton"
        onClick={closeFunction}
        tabIndex={0}
        aria-label="Close notification"
      >
        <IconClose width={"0.875rem"} height={"1.313rem"} color={theme === "dark" ? "white" : "grey"} />
      </div>
    </div>
  ) : (
    <></>
  );
};
